import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './assets/css/g-reset.css'
import './assets/css/erp-content.less'
import './assets/css/el-reset.less'
import ERPcomponents from './erp-components'
import btnAntiShake from './util/directives'
import store from './store'

createApp(App)
  .use(router)
  .use(ElementPlus)
  .use(ERPcomponents)
  .use(btnAntiShake)
  .use(store)
  .mount('#app')