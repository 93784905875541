const state = {
  userInfo: {
    nickName: "",
    companyLogo: "",
    companyId: "",
    workImg: "",
    id: "",
    phone: "",
  },
  controlModuleSet: new Set(),
  permissionMap: new Set(),
  loginCompanyInfo:{}, // 当前登录公getFilterBase司信息
};

const getters = {
  getcontrolModuleSet: (state) => {
    return new Set(state.permissionMap);
  },
  getLoginCompanyInfo: (state) => {
    return state.loginCompanyInfo;
  },
  getUserInfo:(state)=>{
    return state.userInfo;
  }
};

const mutations = {
  setUserInfo:(state,data)=>{
    state.userInfo = data
  },
  setLoginCompanyInfo: (state, data) => {
    state.loginCompanyInfo = data
  }
};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions,
};
