export default [
  {
    name: 'crm-set',
    path: 'crm-set',
    meta: {
      title: '电商设置',
      module: '设置模块',
      keepAlive:true
    },
    component: () => import('@/view/module/personal-setting/crm-set/index')
  },
  {
    name: 'personnel',
    path: 'personnel',
    meta: {
      title: '公司人事树',
      module: '人事模块',
      keepAlive:true
    },
    component: () => import('@/view/module/personal-setting/personnel/index')
  },
  {
    name: 'company-set',
    path: 'company-set',
    meta: {
      title: '公司设置',
      module: '设置',
      keepAlive:true
    },
    component: () => import('@/view/module/personal-setting/company-set/index')
  },
  {
    name: 'goods-set',
    path: 'goods-set',
    meta: {
      title: '公司设置',
      module: '设置',
      keepAlive:true
    },
    component: () => import('@/view/module/personal-setting/goods-set/index')
  },
  {
    name: 'members-set',
    path: 'members-set',
    meta: {
      title: '会员列表',
      module: '设置',
      keepAlive:true
    },
    component: () => import('@/view/module/order-manage/members/index')
  },
  {
    name: 'orderList',
    path: 'orderList',
    meta: {
      title: '订单列表',
      module: '订单列表',
      keepAlive:true
    },
    component: () => import('@/view/module/order-manage/order-list/index')
  },
  {
    name: 'order-detail',
    path: 'order-detail',
    meta: {
      title: '订单详情',
      module: '订单列表',
      keepAlive:true
    },
    component: () => import('@/view/module/order-manage/order-detail/index')
  },
  {
    name: 'invite',
    path: 'invite',
    meta: {
      title: '邀约管理',
      module: '邀约管理',
      keepAlive:false
    },
    component: () => import('@/view/module/order-manage/invite-list/index')
  },
  {
    name: 'data-center',
    path: 'data-center',
    meta: {
      title: '数据中心',
      module: '数据中心',
      keepAlive:true
    },
    component: () => import('@/view/module/data-center/staff-data/index')
  },
]
