import HTTP from "../util/http";
import PERSONNEL from "../util/personnel_http"

// 账号登录
export function loginByPass(postData){
    return HTTP.post("common_login/login_by_pass", postData);
};

// 登录Token
export function initToken(postData){
    return HTTP.post("common_login/login_by_uct", postData);
};
//获取基础信息
//获取当前权限码
export function getPermission(postGroup){
    return PERSONNEL.get('/common_login/get_staff_base_info', {
        params: postGroup
    })
}

// 上传阿里云OSS
export function ossUpload(url, formData){
    return HTTP.post(url, formData);
};

// 获取渠道组数据
export function getChannelInfoList(params){
    return HTTP.get("/channel/get_parent_channel", { params });
};

// 渠道删除
export function delChannel(postData){
    return HTTP.post("/channel/del_channel", postData);
};

// 渠道图标列表
export function listChannelImg(params){
    return HTTP.get("/channel/list_channel_img", { params });
};

// 渠道信息排序
export function exchangePriority(postData){
    return HTTP.post("/channel/exchange_Priority", postData);
};

// 保存logo、名称等
export function uploadCompany(params){
    return HTTP.post('company/update_company', params)
}

//充值
export function rechargePre(params){
    return HTTP.get('recharge/recharge_pre', { params })
}
//充值列表
export function getRecharge(data){
    return HTTP.post('recharge/get_recharge', data)
}
//账户余额
export function getCompanyAmount(params){
    return HTTP.get('company/get_company', {params})
}

// 发送记录
export function getSendMsgLog(data){
    return HTTP.post('msg_template/get_send_msg_log', data)
}