const importFn = require.context('./', true, /\.(vue|js)$/)
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
export default {
  install (app) {
    // 根据keys批量注册
    importFn.keys().forEach(key => {
      // 导入组件
      // default 默认导出的内容 组件中export default {}
      if (key != './index.js'){
        const component = importFn(key).default
        // 注册组件
        app.component(component.name || component.__name, component)
      }

    })
    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
      app.component(key, component)
    }
  }
}
