<template>
	<div class="calendar-table">
		<!--loading-->
		<div class="el-loading-mask" v-show="props.loading">
			<div class="el-loading-spinner">
				<svg viewBox="25 25 50 50" class="circular">
					<circle cx="50" cy="50" r="20" fill="none" class="path"></circle>
				</svg>
			</div>
		</div>
		
		<!--时间筛选-->
		<div class="calendar-table-date">
			<div class="chooseMonth">
				<el-button size="small" @click="prefMonth(false)">上月</el-button>
				<span class="middle">{{ monthText() }}</span>
				<el-date-picker
					v-model="_data.timeConfig.month"
					:clearable="false"
					@change="initData"
					size="small"
					type="month"
					id="custom-date-picker"
					class="custom-date-picker"
				>
				</el-date-picker>
				<el-button style="margin-left: 10px" size="small" @click="prefMonth(true)">下月</el-button>
        <!-- 店门模式 -->
        <div class="shop-mode">
          <slot name="shop-mode"></slot>
        </div>
			</div>
		</div>
		<!--星期维度-->
		<ul class="wall-calendar-week">
			<li v-for="(item, index) in _data.weekArray" :key="index">{{ item }}</li>
		</ul>
		<!--挂历列表-->
		<div class="wall-con">
			<div class="wall-calendar flex">
				<div class="wall-lf">
					<!--挂历-->
					<div class="wall-calendar-center">
						<ul v-if="contentData.length">
							<li v-for="(item, index) in _data.prefArray" :key="'pref' + index">
								<span class="pref"></span>
							</li>
							<li v-for="(item,index) in contentData" :key="index">
								<slot name="content" v-bind:row="{ item: item}"></slot>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import TimeUtil from "@/util/time-plugin";
import {reactive, onMounted, ref,nextTick} from 'vue'
const props = defineProps({
  //子组件接收父组件传递过来的值
  contentData: Array,
  loading:Boolean
})
const emit = defineEmits(['timeChange'])
const _data = reactive({
  weekArray: ["一", "二", "三", "四", "五", "六", "日"],
  prefArray: [],
  selectMonth: new Date(),
  timeConfig: {
    month: new Date(),
  },
  tableData: [],
  toDay: 0,
})


onMounted(()=>{
  _data.toDay = new Date(new Date().toLocaleDateString()).getTime() / 1000;
  initMonth()
})


const initData = (()=>{
  initMonth()
})

const initMonth = (()=>{
  _data.prefArray = [];
  // 上个月最后一天
  let lastDay = new Date(_data.timeConfig.month.getFullYear(), _data.timeConfig.month.getMonth(), 0).getDate();
  // 当前月第一天星期维度
  let lastMonthDay = new Date(_data.timeConfig.month.getFullYear(), _data.timeConfig.month.getMonth(), 1).getDay();
  if (lastMonthDay > 1 || lastMonthDay == 0) {
    // 循环次数
    let forIndex = lastMonthDay == 0 ? 5 : lastMonthDay - 2;
    for (let i = 0; i <= forIndex; i++) {
      _data.prefArray.push({
        day: lastDay + forIndex,
      });
    }
  }
  emit('timeChange',_data.timeConfig.month)
})

const monthText = (()=>{
  let year = _data.timeConfig.month.getFullYear();
  let month =
    _data.timeConfig.month.getMonth() + 1 > 9
      ? _data.timeConfig.month.getMonth() + 1
      : `0${_data.timeConfig.month.getMonth() + 1}`;
  return `${year}-${month}`;
})

const prefMonth = ((flag)=>{
  let currentTime = _data.timeConfig.month;
  let month = currentTime.getMonth();
  let year = currentTime.getFullYear();
  if (month == 12) {
    year = flag ? year + 1 : year;
    month = flag ? 1 : month;
  } else if (month == 1) {
    year = flag ? year : year - 1;
    month = flag ? month + 1 : 12;
  } else {
    month = flag ? month + 1 : month - 1;
  }
  _data.timeConfig.month = new Date(year, month, 1);
  initData();
})

const timeChange = (()=>{
  emit('timeChange',_data.timeConfig.month)
})
defineExpose({
  timeChange
})
</script>

<style lang="less" scoped>
.calendar-table {
	padding: 60px 20px;
	height: 100%;
	position: relative;
	.wall-calendar-week {
		max-width: 1200px;
		margin: 0 auto;
		font-size: 12px;
		color: #999;
		display: flex;
		align-items: center;
    background-color: #fff;
		
		li {
			width: 14.28%;
			height: 40px;
			line-height: 40px;
			text-align: center;
		}
	}
	.calendar-table-date {
		font-size: 12px;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		padding: 16px 10px;
		height: 60px;
		border-bottom: 1px #e2e2e2 solid;
		background: #fff;
		display: flex;
		align-items: center;
		
		.chooseMonth {
			position: relative;
			width: 100%;
			display: flex;
      align-items: center;
			.middle {
				margin: 0 10px;
			}
			
			.site-swp {
				position: absolute;
				left: 50%;
			}
			
			.site-swp-Mode {
				position: absolute;
				right: 50px;
			}

      .shop-mode {
        position: absolute;
        right: 0;
        top: 0;
      }
		}
		
		.custom-date-picker {
			height: 28px !important;
			position: absolute;
			left: 190px;
			top: 0px;
			cursor: pointer;
		}
	}
	
	.flex {
		display: flex;
		justify-content: space-between;
	}
	
	.wall-con {
		height: 100%;
		overflow: auto;
		//margin-top: 10px;
	}
	.wall-calendar {
		margin: 0px auto;
		max-width: 1200px;
		width: 100%;
		background: #ffffff;
		.wall-lf {
			width: 100%;
			.wall-calendar-center {
				font-size: 12px;
				color: #333;
				
				ul {
					display: flex;
					align-items: center;
					flex-wrap: wrap;
					border-top: 1px #e2e2e2 solid;
					border-left: 1px #e2e2e2 solid;
					
					li {
						cursor: pointer;
						width: 14.28%;
						height: 100px;
						//min-height: 135px;
						border-right: 1px #e2e2e2 solid;
						border-bottom: 1px #e2e2e2 solid;
						// padding: 4px;
						position: relative;
						.pref {
							color: #999;
						}
					}
				}
			}
		}
	}
}
</style>

<style lang="less">
.calendar-table {
	#custom-date-picker {
		width: 10px;
		height: 28px !important;
		padding-right: 0;
		cursor: pointer;
		/*border: 0px;*/
	}
	
	.custom-date-picker {
		.el-input__prefix {
			.el-input__icon {
				width: 22px !important;
			}
		}
	}
  .el-date-editor.el-input, .el-date-editor.el-input__wrapper{
    //display: flex;
  }
}
</style>
