/*-- 时间工具 --*/
//获取当前时间，格式"yyyy-MM-DD",例：2017-01-05
function getYmdToday() {
  var day = new Date();
  var Year = 0;
  var Month = 0;
  var Day = 0;
  var CurrentDate = "";
  Year = day.getFullYear();
  Month = day.getMonth() + 1;
  Day = day.getDate();
  CurrentDate += Year + "-";
  if (Month >= 10) {
    CurrentDate += Month + "-";
  } else {
    CurrentDate += "0" + Month + "-";
  }
  if (Day >= 10) {
    CurrentDate += Day;
  } else {
    CurrentDate += "0" + Day;
  }
  return CurrentDate;
}
// 获取昨日
function getYesterDay() {
  var dd = new Date();
  dd.setDate(dd.getDate() - 1);// 获取AddDayCount天后的日期
  var y = dd.getFullYear();
  var m = dd.getMonth() + 1;// 获取当前月份的日期
  var d = dd.getDate();
  if (m < 10) {
    m = "0" + m;
  }
  if (d < 10) {
    d = "0" + d;
  }
  return y + "-" + m + "-" + d;
}
//获取明天
function getTomorrow() {
  var dd = new Date();
  dd.setDate(dd.getDate() + 1);// 获取AddDayCount天后的日期
  var y = dd.getFullYear();
  var m = dd.getMonth() + 1;// 获取当前月份的日期
  var d = dd.getDate();
  if (m < 10) {
    m = "0" + m;
  }
  if (d < 10) {
    d = "0" + d;
  }
  return y + "-" + m + "-" + d;
}



// 获取当月第一天，格式"yyyy-MM-DD",例：2017-01-01
function getYmdMonthFirst() {
  var day = new Date();
  var Year = 0;
  var Month = 0;
  var firstDate = "";
  Year = day.getFullYear();
  Month = day.getMonth() + 1;

  firstDate += Year + "-";
  if (Month >= 10) {
    firstDate += Month + "-";
  } else {
    firstDate += "0" + Month + "-";
  }
  firstDate += "01";
  return firstDate;
}

// 获取当月最后一天，格式"yyyy-MM-DD",例：2017-01-31
function getYmdMonthLast() {
  var date = new Date();
  var currentMonth = date.getMonth();
  var nextMonth = ++currentMonth;
  var nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
  var oneDay = 1000 * 60 * 60 * 24;
  var day = new Date(nextMonthFirstDay - oneDay);
  var Year = 0;
  var Month = 0;
  var lastDate = "";
  Year = day.getFullYear();
  Month = day.getMonth() + 1;
  var Day = day.getDate();
  lastDate += Year + "-";
  if (Month >= 10) {
    lastDate += Month + "-";
  } else {
    lastDate += "0" + Month + "-";
  }
  if (Day >= 10) {
    lastDate += Day;
  } else {
    lastDate += "0" + Day;
  }
  return lastDate;
}

// 获取上月第一天，格式"yyyy-MM-DD",例：2017-01-01
function getLastYmdMonthFirst() {

  var nowdays = new Date();
  var year = nowdays.getFullYear();
  var month = nowdays.getMonth();
  if (month == 0) {
    month = 12;
    year = year - 1;
  }
  if (month < 10) {
    month = "0" + month;
  }
  var firstDay = year + "-" + month + "-" + "01";// 上个月的第一天


  return firstDay;
}

// 获取上月最后一天，格式"yyyy-MM-DD",例：2017-01-01
function getLastYmdMonthEnd() {

  var nowdays = new Date();
  var year = nowdays.getFullYear();
  var month = nowdays.getMonth();
  if (month == 0) {
    month = 12;
    year = year - 1;
  }
  if (month < 10) {
    month = "0" + month;
  }

  var myDate = new Date(year, month, 0);
  var lastDay = year + "-" + month + "-" + myDate.getDate();// 上个月的最后一天

  return lastDay;
}


/*-- 获取本周第一天 --*/
function getFirstYmdThisWeek() {
  var now = new Date();
  var nowTime = now.getTime();
  var day = now.getDay();
  if (day == 0) {
    day = 7
  }
  var oneDayLong = 24 * 60 * 60 * 1000;

  var MondayTime = nowTime - (day - 1) * oneDayLong;

  var m = new Date(MondayTime);
  var month = m.getMonth() + 1;
  var date = m.getDate();
  if (month < 10) {
    month = "0" + month;
  }
  if (date < 10) {
    date = "0" + date;
  }

  var starttime = m.getFullYear() + '-' + month + '-' + date;

  return starttime;
}

/*-- 获取本周最后一天 --*/
function getLastYmdThisWeek() {
  var now = new Date();
  var nowTime = now.getTime();
  var day = now.getDay();
  var oneDayLong = 24 * 60 * 60 * 1000;
  var SundayTime = nowTime + ((7 - day)%7 )* oneDayLong;
  var s = new Date(SundayTime);

  var month = s.getMonth() + 1;
  var date = s.getDate();
  if (month < 10) {
    month = "0" + month;
  }
  if (date < 10) {
    date = "0" + date;
  }

  let endtime = s.getFullYear() + '-' + month + '-' + date;
  return endtime;
}

/*-- 获取上周第一天 --*/
function getLastWeekFistDay() {
  var now = new Date();
  var nowTime = now.getTime();
  var day = now.getDay();
  var oneDayLong = 24 * 60 * 60 * 1000;
  var MondayTime = nowTime - (day + 6) * oneDayLong;

  var m = new Date(MondayTime);
  var month = m.getMonth() + 1;
  var date = m.getDate();
  if (month < 10) {
    month = "0" + month;
  }
  if (date < 10) {
    date = "0" + date;
  }

  let starttime = m.getFullYear() + '-' + month + '-' + date;

  return starttime;
}

/*-- 获取上周最后一天 --*/
function getLastWeekLastDay() {
  var now = new Date();
  var nowTime = now.getTime();
  var day = now.getDay();
  var oneDayLong = 24 * 60 * 60 * 1000;

  var SundayTime = nowTime + (0 - day) * oneDayLong;

  var s = new Date(SundayTime);
  var month = s.getMonth() + 1;
  var date = s.getDate();
  if (month < 10) {
    month = "0" + month;
  }
  if (date < 10) {
    date = "0" + date;
  }

  let endtime = s.getFullYear() + '-' + month + '-' + date;
  return endtime;
}





/*-- 获取本季度第一天 --*/
function getFirstDayOfThisQuarter() {
  var now = new Date();
  var year = now.getFullYear();
  var month = now.getMonth();
  if (month < 3) {
    now.setMonth(0);
  } else if (2 < month && month < 6) {
    now.setMonth(3);
  } else if (5 < month && month < 9) {
    now.setMonth(6);
  } else if (8 < month && month < 12) {
    now.setMonth(9);
  }
  now.setDate(1);
  month = now.getMonth();
  month += 1;
  if (month < 10) {
    month = "0" + month;
  }
  return year + "-" + month + "-01";
}

/*-- 获取本季度最后一天 --*/
function getEndDayOfThisQuarter() {
  var now = new Date();
  var year = now.getFullYear();
  var month = now.getMonth();
  if (month < 3) {
    now.setMonth(2);
  } else if (2 < month && month < 6) {
    now.setMonth(5);
  } else if (5 < month && month < 9) {
    now.setMonth(8);
  } else if (8 < month && month < 12) {
    now.setMonth(11);
  }
  now.setDate(getDayNumOfMonth(now.getMonth()));
  month = now.getMonth();
  month += 1;
  if (month < 10) {
    month = "0" + month;
  }
  var day = now.getDate();
  if (day < 10) {
    day = "0" + day;
  }
  return year + "-" + month + "-" + day;
}

/*-- 获取上季度第一天 --*/
function getFirstDayOfLastQuarter() {
  var now = new Date();
  var year = now.getFullYear();
  var month = now.getMonth();
  if (month < 3) {
    year -= 1;
    now.setMonth(9);
  } else if (2 < month && month < 6) {
    now.setMonth(0);
  } else if (5 < month && month < 9) {
    now.setMonth(3);
  } else if (8 < month && month < 12) {
    now.setMonth(6);
  }
  now.setDate(1);
  month = now.getMonth();
  month += 1;
  if (month < 10) {
    month = "0" + month;
  }
  return year + "-" + month + "-01";
}

/*-- 获取上季度最后一天 --*/
function getEndDayOfLastQuarter() {
  var now = new Date();
  var year = now.getFullYear();
  var month = now.getMonth();
  if (month < 3) {
    year -= 1;
    now.setMonth(11);
  } else if (2 < month && month < 6) {
    now.setMonth(2);
  } else if (5 < month && month < 9) {
    now.setMonth(5);
  } else if (8 < month && month < 12) {
    now.setMonth(8);
  }
  now.setDate(getDayNumOfMonth(now.getMonth()));
  month = now.getMonth();
  month += 1;
  if (month < 10) {
    month = "0" + month;
  }
  var day = now.getDate();
  if (day < 10) {
    day = "0" + day;
  }
  return year + "-" + month + "-" + day;
}

/*-- 获取某月天数,不考虑闰年二月 --*/
function getDayNumOfMonth(month) {
  var num = 0;
  if (month == 0 || month == 2 || month == 4 || month == 6 || month == 7
    || month == 9 || month == 11) {
    num = 31;
  } else if (month == 3 || month == 5 || month == 8 || month == 10) {
    num = 30;
  } else if (num == 1) {
    num = 28;
  }
  return num;
}








export default {
  getYmdToday, getYesterDay, getTomorrow, getFirstYmdThisWeek, getLastYmdThisWeek, getLastYmdMonthFirst,
  getYmdMonthFirst, getYmdMonthLast, getLastWeekFistDay, getLastWeekLastDay,
  getFirstDayOfLastQuarter, getEndDayOfLastQuarter, getFirstDayOfThisQuarter, getEndDayOfThisQuarter, getLastYmdMonthEnd
}
