<template>
  <div class="erp-product">
    <el-dialog
      class="custom-dialog"
      v-model="_data.productShow"
      width="850px"
      title="产品列表"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <div class="product-list_header">
        <div class="search-input">
          <el-input
            placeholder="输入关键字"
            v-model="_data.searchDetail"
            @keyup.enter.native="searchData"
            size="small"
            style="line-height: 28px"
          >
            <template #suffix>
              <svg class="icon" aria-hidden="true" @click="searchData">
                <use xlink:href="#icon-sousuo"></use>
              </svg>
            </template>
          </el-input>
        </div>
        <div class="btn-box">
          <el-button v-no-more-click @click="closeProductDialog" size="small" round>取 消</el-button>
          <el-button v-no-more-click type="primary" @click="pushCurrentChoosedList" size="small" round>保 存</el-button>
        </div>
      </div>

      <div class="project-server">
        <div class="project-server-class">
          <p class="project-server-title">
            商品库
          </p>
          <div class="project-server-core">

            <div class="serve-core">
              <div class="serve-core-label" :class="{ 'check-group': _data.classify.id == 0 }">搜索</div>
            </div>

            <div class="serve-core" v-for="(core, index) in _data.categoryList" :key="index">
              <div class="serve-core-label" :class="{ 'check-group': _data.classify.id == core.id }" @click.stop="getGroup(core)">
                <span>{{ core.name }}</span>
              </div>
              <div class="core-class">
                <div class="core-class-menu" v-for="(coreClass, index) in core.categoryChildList" :key="index">
                  <div class="core-class-group"
                       @click="getGroup(coreClass)"
                       :class="{ 'check-group': _data.classify.id == coreClass.id }"
                  >- {{ coreClass.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="project-server-rigth">
          <!--小组人员Table-->
          <el-table
            :data="_data.partTable.data"
            v-loading="_data.partTable.loading"
            height="550"
            element-loading-text="数据加载中"
            size="small"
            :header-cell-style="{ background: '#f4f4f4', color: '#000' }"
          >
            <el-table-column label="序号" type="index" width="50"></el-table-column>
            <el-table-column prop="projectName" label="项目名称" width="150" show-overflow-tooltip></el-table-column>
            <el-table-column prop="specification" label="规格" width="80"></el-table-column>
            <el-table-column prop="unit" label="单位" width="80"></el-table-column>
            <el-table-column prop="salePrice" label="售价" width="80">
              <template #default="scope">{{ scope.row.salePrice / 100 }}</template>
            </el-table-column>
            <el-table-column prop="projectNumber" label="次/疗程" width="80"></el-table-column>
            <el-table-column prop="projectNumber" label="操作" width="80" fixed="right">
              <template #default="scope">
                <el-button size="small" link @click="add(scope.row)" type="primary">添加</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="product-choosed-container">
        <div class="current_choosed_head">
          <div class="choosed_info">已选中 ( {{_data.productList.length}} )</div>
        </div>
        <div class="current_choosed_body">
          <el-tag
            v-for="(tag, index) in _data.productList"
            :key="index"
            closable
            style="margin: 5px"
            size="small"
            @close="cancelChoosedProduct(index)"
          >
            {{ tag.projectName }}
          </el-tag>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import {reactive,onMounted,ref,defineEmits} from 'vue'
import SETAPI from "@/view/module/personal-setting/set-api";
import TimeUtil from "@/util/time-util";
const emit = defineEmits(['getProductList'])
  const _data = reactive({
    searchDetail:'',
    productShow:false,
    classify:{
      id:0,
      name:''
    },//暂存
    categoryList:[],//分类列表
    partTable:{
      data:[],
      loading:false,
    },
    productList:[]
  })

const add = ((row)=>{
  _data.productList.push(row)
})
const searchData = (()=>{
  _data.classify.id = 0
  getProjectList()
})
//删除
const cancelChoosedProduct = ((index)=>{
  _data.productList.splice(index,1)
})
//获取分类
const getClassList = ()=>{
  SETAPI.getCategoryProjectList({}).then(res=>{
    _data.categoryList = res.data
    getGroup(res.data[0] || {})
  })
}
//暂存点击分类
const getGroup = (coreClass)=>{
  let {id,name} = coreClass
  _data.searchDetail = ''
  _data.classify.id = id
  _data.classify.name = name
  _data.tabsId = 1
  getProjectList()
}
// //获取商品列表
const getProjectList = ()=>{
  _data.partTable.loading = true
  SETAPI.getProjectList({
    categoryId:_data.classify.id,
    key:_data.searchDetail,
    showFlag:true,
    pageSize:9999
  }).then(res=>{
    _data.partTable.loading = false
    if(res.code == 100000){
      _data.partTable.data = res.data.list
    }
  })
}

const productInitAll = (()=>{
  _data.productShow = true
})
const closeProductDialog = (()=>{
  _data.productShow = false
  _data.productList = []

})
//点击保存
const pushCurrentChoosedList = (()=>{
  emit('getProductList',_data.productList)
  closeProductDialog()
})

defineExpose({
  productInitAll
})
getClassList()
</script>

<style scoped lang="less">
  .erp-product{
    .product-list_header {
      width: calc(100% - 100px);
      position: absolute;
      right: 20px;
      top: 20px;
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn-box {
        float: right;
      }
      .search-input {
        width: 200px;
        display: inline-block;
      }
    }
    .project-server {
      height: 550px;
      width: 100%;
      border: 1px #d9d9d9 solid;
      border-radius: 8px;
      display: flex;
      overflow: hidden;
      .project-server-class {
        width: 200px;
        border-right: 1px #e2e2e2 solid;

        .project-server-title {
          padding-left: 20px;
          height: 50px;
          line-height: 50px;
          color: #333;
          font-weight: 500;
          background: #f7f7f7;
          border-bottom: 1px #e2e2e2 solid;
        }
        .set-center-icon {
          display: inline-block;
          font-weight: normal;
          width: 24px;
          height: 24px;
          border-radius: 4px;
          border: 1px #d9d9d9 solid;
          text-align: center;
          line-height: 20px;
          font-size: 12px;
          color: #333;
          margin-left: auto;
          cursor: pointer;
          opacity: 0.5;
          margin-right: 5px;
        }
        .set-center-icon:hover,
        .post-server-core .serve-core .serve-core-label .center-icon:hover {
          color: #2f88ff;
          border-color: #2f88ff;
        }
        .project-server-core{
          height: calc(100% - 50px);
          color: #333;
          overflow-y: auto;
          .serve-core {
            .serve-core-label {
              height: 40px;
              line-height: 40px;
              cursor: pointer;
              padding: 0 20px;
              display: flex;
              align-items: center;

              .set-center-icon {
                align-items: center;
              }

              span {
                font-weight: 600;
              }

              .center-icon {
                font-size: 14px;
                color: #333;
                font-weight: normal;
              }
            }
            .serve-core-label:hover {
              background: #daefff;
              color: #2f88ff;
            }
            .drop-down-icon {
              font-size: 14px;
              color: #999;
              cursor: pointer;
              margin-left: 10px;
              transition: all 0.5s;
              transform: rotate(-180deg);
            }

            .set-center-icon {
              margin-left: auto;
            }

            .open-down-icon {
              transform: rotate(0deg);
            }

            .core-class-menu {
              font-size: 12px;
              cursor: pointer;

              &:hover {
                background: #daefff;
                color: #2f88ff;
              }

              .core-class-group {
                padding-left: 40px;
                padding-right: 10px;
                height: 40px;
                line-height: 40px;
              }
            }

            .check-group {
              color: #2f88ff;
              background: #daefff;
              position: relative;

              &:after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 3px;
                height: 100%;
                background: #2f88ff;
              }
            }
          }
        }
      }

      .project-server-rigth{
        width: calc(100% - 200px);
        position: relative;
        height: 100%;
      }
    }
    .product-choosed-container {
      z-index: -1;
      position: absolute;
      left: 100%;
      top: 50px;
      width: 350px;
      height: 500px;
      background: #fff;
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
      overflow: hidden;

      .current_choosed_head {
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: rgb(242, 242, 242);
        padding: 0 20px;
        .choosed_info {
          font-size: 14px;
          font-weight: bold;
        }
      }

      .current_choosed_body {
        width: 100%;
        height: calc(100% - 50px);
        overflow: auto;

        &:hover {
          cursor: pointer;
        }
      }
    }

  }
</style>
<style lang="less">
.erp-product{
  .custom-dialog{
    .el-dialog__body{
      box-sizing: border-box;
      padding: 20px;
    }
  }
}
</style>
