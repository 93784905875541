import HTTP from "@/util/http";
import personnel from '@/util/personnel_http'

const formatUrl = (url) => {
  return url
}
//获取角色列表
const roleMenu = (postData) => {
  return personnel.post("/personnel/get_role_list", postData);
};
// 人员离职
const delStaffInfo = (postData) => {
  return HTTP.post("/personnel/del_staff_info", postData);
};
// 渠道图标列表
const listChannelImg = (params) => {
  return HTTP.get("/channel/list_channel_img", {params});
};
// 渠道信息排序
const exchangePriority = (postData) => {
  return HTTP.post("/channel/exchange_Priority", postData);
};
/**
 * 获取渠道列表
 * typeIds：电商1,2 、转介绍3，4 、 异业联盟5
 */
const get_channel_info_list = (params) => {
  return HTTP.get("/channel/get_channel_info_list", {params});
};
//人事树
const getPersonnelCenter = (postData) => {
  return HTTP.post("/personnel/get_personnel_construction", postData);
};

// 获取小组和客服数组
const getGroupStaffList = (params) => {
  return HTTP.get("/group_staff/get_group_staff_list", {params});
};
// 获取渠道组数据
const getChannelInfoList = (params) => {
  return HTTP.get("/channel/get_parent_channel", {params});
};
// 获取渠道组子集数据
const getChildChannelList = (params) => {
  return HTTP.get("/channel/get_child_channel_list", {params});
};
// 新增渠道小组
const addChannel = (postData) => {
  return HTTP.post("/channel/add_channel", postData);
};
// 编辑渠道信息
const updateChannelInfo = (postData) => {
  return HTTP.post("/channel/update_channel_info", postData);
};
// 批量编辑渠道信息
const batchUpdateChannelInfo = (postData) => {
  return HTTP.post("/channel/batch_update_channel_info", postData);
};
// 渠道删除
const delChannel = (postData) => {
  return HTTP.post("/channel/del_channel", postData);
};
// 渠道转移
const transferClue = (postData) => {
  return HTTP.post("/channel/transfer_clue", postData);
};
// 获取小组
const getChildStructureOptionList = (postData) => {
  return HTTP.post("/personnel/get_child_structure_option_list", postData);
};
// 岗位
const getGroupTypeList = (params) => {
  return HTTP.get("/personnel/get_group_type_list", {
    params: params
  });
}
// 添加小组
const addNewGroup = (postData) => {
  return HTTP.post("/personnel/add_new_group", postData);
};
// 删除小组
const deleteGroup = (postData) => {
  return HTTP.post("/personnel/delete_group", postData);
};
// 编辑部门
const editGroupInfn = (postData) => {
  return HTTP.post("/personnel/edit_group_info", postData);
};
// 编辑小组人员信息
const editStaffInfo = (postData) => {
  return HTTP.post("/personnel/edit_group_staff_info", postData);
};
// 新增小组人员信息
const addStaffInfo = (postData) => {
  return HTTP.post("/personnel/add_group_staff", postData);
};
// 导入人员
const exportGroupStaff = (postData) => {
  return HTTP.post("/personnel/export_group_staff", postData);
};
// 获取角色
const findDictionary = params => {
  return HTTP.get('/dictionary/find_dictionary', {params})
}
const editStaffLockFlag = (postData) => {
  return HTTP.post("/personnel/edit_staff_lock_flag", postData);
};
//新增角色权限
const addPermission = (postData) => {
  return HTTP.post("/personnel/add_role_permission", postData);
}
//删除角色权限
const delPermission = (postData) => {
  return HTTP.post("/personnel/delete_role_permission", postData);
}

// 删除角色分组
const deletedRoleGroup = postData => {
  return HTTP.post("personnel/del_role_info", postData);
}
// 添加角色
const addNewRole = (postData) => {
  return HTTP.post("/personnel/add_new_role", postData);
}
// 编辑角色
const editRole = (postData) => {
  return HTTP.post("/personnel/edit_role_info", postData);
}

// 获取小组类型下拉
const staffOptionTransfer = params => {
  return HTTP.get('group_staff/get_group_staff_cascader_by_staffId', {
    params: params
  })
}

// 公司人事树 - 搜索
const searchPersonnel = (postData) => {
  return HTTP.post("/personnel/search", postData);
};


// 刷新人员关注状态
const refreshFollowStatus = (params) => {
  return personnel.get('/personnel/refresh_follow_status', {params})
}

// 全员认证刷新
const refreshAllFollowStatus = (params) => {
  return personnel.get('/personnel/refresh_all_staff_follow_status', {params})
}

//获取商品分类
const getSecondCategoryList = (params) =>{
  return HTTP.get('/product_category/get_second_category_list', {params})
}
//获取产品
const getProductList = (data) =>{
  return HTTP.post('/product/get_product_list', data)
}
//新增产品
const saveProduct = (data) =>{
  return HTTP.post('/product/save_product', data)
}
//新增供应商
const saveSupplier = (data) =>{
  return HTTP.post('/product_supplier/save_product_supplier', data)
}

//获取供应商
const getSupplier = (data) =>{
  return HTTP.post('/product_supplier/get_product_supplier_list', data)
}

//增删改规格
const saveProductSpecification = (data)=>{
  return HTTP.post('/product_specification/save_product_specification', data)
}

//规格列表
const getProductSpecification = (data)=>{
  return HTTP.post('/product_specification/get_product_specification', data)
}

//查询项目分类
const getCategoryProjectList = (data)=>{
  return HTTP.get('/project_category/get_second_category_list', data)
}
//新建分类
const saveProjectCategory = (data)=>{
  return HTTP.post('/project_category/save_project_category', data)
}

//查询项目列表
const getProjectList = (data)=>{
  return HTTP.post('/project/get_project_list', data)
}

//新建/编辑项目产品
const saveProject = (data)=>{
  return HTTP.post('/project/save_project', data)
}


//新增/编辑支付方式
const savePay = (data)=>{
  return HTTP.post('/pay/save_pay', data)
}

//获取支付方式
const getPayList = (data)=>{
  return HTTP.get('/pay/get_pay_list', {data})
}

//门店设置
const saveShop = (data)=>{
  return HTTP.post('/shop/save_shop', data)
}
//获取门店列表
const getShopList = (data)=>{
  return HTTP.get('/shop/get_shop_list', {data})
}
//来源
const getChannelList = (params)=>{
  return HTTP.get('/channel/get_channel_info_list', {params})
}

//使用人
const getMemberProject = data =>{
  return HTTP.post('/order_project/get_member_project',data)
}

export default {
  transferClue,
  deletedRoleGroup,
  delStaffInfo,
  staffOptionTransfer,
  editRole,
  addNewRole,
  delPermission,
  addPermission,
  findDictionary,
  exportGroupStaff,
  addStaffInfo,
  editStaffInfo,
  editGroupInfn,
  deleteGroup,
  addNewGroup,
  getGroupTypeList,
  getChildStructureOptionList,
  delChannel,
  batchUpdateChannelInfo,
  updateChannelInfo,
  addChannel,
  getChildChannelList,
  getChannelInfoList,
  getGroupStaffList,
  exchangePriority,
  listChannelImg,
  roleMenu,
  get_channel_info_list,
  getPersonnelCenter,
  editStaffLockFlag,
  searchPersonnel,
  refreshFollowStatus,
  refreshAllFollowStatus,
  getSecondCategoryList,
  getProductList,
  saveProduct,
  saveSupplier,
  getSupplier,
  saveProductSpecification,
  getProductSpecification,
  getCategoryProjectList,
  saveProjectCategory,
  getProjectList,
  saveProject,
  savePay,
  getPayList,
  saveShop,
  getShopList,
  getChannelList,
  getMemberProject
};
