// import timeUtil from "./time-util";
import solarLunar from 'solarlunar';

const timePlugin = {}
const weekArray = ['星期日','星期一','星期二','星期三','星期四','星期五','星期六']

//获取当前月份天数
timePlugin.currentMonth = (date)=>{
  let currentDate = new Date(date)
  let year = currentDate.getFullYear()
  let month = currentDate.getMonth()+1
  let MonthNumber = new Date(year,month,0).getDate()
  return MonthNumber
}

//获取当前星期
timePlugin.currentWeek = (date,day)=>{
  let currentDay = new Date(date)
  currentDay.setDate(day)
  return weekArray[currentDay.getDay()]
}

//是否是当天
timePlugin.currentDay = (date,day)=>{
  let beforDay = new Date(date)
  beforDay.setDate(day)
  beforDay.setHours(0)
  beforDay.setMinutes(0)
  beforDay.setSeconds(0)
  beforDay.setMilliseconds(0)
  let nowDay = new Date()
  nowDay.setHours(0)
  nowDay.setMinutes(0)
  nowDay.setSeconds(0)
  nowDay.setMilliseconds(0)
  return beforDay.valueOf() == nowDay.valueOf()?true:false
}

//返回指定月份天数 当天月份
timePlugin.getMonthOfWeek = (date)=>{
  timePlugin.currentDay(date,1)
  let m = new Date(date).getMonth() + 1
  // let currentDay = new Date(date).getDate()
  let monthNumber = timePlugin.currentMonth(date)
  let data = []
  for(let i=1;i<=monthNumber;i++){
    data.push({
      'index':i,
      'day':`${m}-${i>9?i:'0'+i}`,
      'week':timePlugin.currentWeek(date,i),
      'current':timePlugin.currentDay(date,i),
    })
  }
  return {data,monthNumber}
}


//时间转化 字符串格式
timePlugin.getDateStr = (date)=>{
  let strDate = new Date(date)
  let y = strDate.getFullYear()
  let m = strDate.getMonth()+1
  return `${y}-${m}`
}
//根据日期返回年月日
timePlugin.getDateAllStr = (date)=>{
  let nowDate = new Date(date)
  let y = nowDate.getFullYear()
  let m = nowDate.getMonth() + 1
  let d = nowDate.getDate()
  return `${y}/${m>=10?m:'0'+m}/${d>=10?d:'0'+d}`
}
//根据日期返回年月日
timePlugin.getDateAllStr2 = (date)=>{
  let nowDate = new Date(date)
  let y = nowDate.getFullYear()
  let m = nowDate.getMonth() + 1
  let d = nowDate.getDate()
  return `${y}-${m>=10?m:'0'+m}-${d>=10?d:'0'+d}`
}
//根据日期返回年月日
timePlugin.getDateChineseStr = (date)=>{
  let nowDate = new Date(date)
  let y = nowDate.getFullYear()
  let m = nowDate.getMonth() + 1
  let d = nowDate.getDate()
  return `${y}年 ${m>=10?m:'0'+m}月 ${d>=10?d:'0'+d}日`
}
//半为 维度 获取 9-10
timePlugin.getHalfTime = ()=>{
  let hours = []
  for(let i=9; i<22; i++){
    hours.push(
      {
        'hours':`${i<10?'0'+i:i}:00`,
        'half':`${i<10?'0'+i:i}:30`
      },
    )
  }
  return hours
}

//转换时间戳为 时分秒
timePlugin.transferDate = (date)=>{
   let nowDate = new Date(parseInt(date) * 1000)
   let h = nowDate.getHours()
   let minute = nowDate.getMinutes();
   let str = `${h<10?'0'+h:h}:${minute<10?'0'+minute:minute}`
   return str
}
//转换时间戳为 时分秒
timePlugin.transferDateS = (date)=>{
  let nowDate = new Date(parseInt(date) * 1000)
  let h = nowDate.getHours()
  let minute = nowDate.getMinutes();
  let s = nowDate.getSeconds()
  let str = `${h<10?'0'+h:h}:${minute<10?'0'+minute:minute}:${s<10?'0'+s:s}`
  return str
}
//转换日期格式 时分秒
timePlugin.transferHM = (date)=>{
   let nowDate = new Date(date)
   let h = nowDate.getHours()
   let minute = nowDate.getMinutes();
   let str = `${h<10?'0'+h:h}:${minute<10?'0'+minute:minute}`
   return str
}

//转换日期格式 时分秒
timePlugin.transferHMStamp = (date)=>{
   let nowDate = new Date(parseInt(date) * 1000)
   let h = nowDate.getHours()
   let minute = nowDate.getMinutes();
   let str = `${h<10?'0'+h:h}:${minute<10?'0'+minute:minute}`
   return str
}

//转换时间戳为日期格式
timePlugin.transferStamp = (date,h)=>{
   let nowDate = new Date(parseInt(date) * 1000)
   let y = nowDate.getFullYear()
   let m = nowDate.getMonth()+1
   let d = nowDate.getDate()
   let str = ''
    if(h){
     let h = nowDate.getHours()
     let mm = nowDate.getSeconds()
      str = `${y}/${m>10?m:'0'+m}/${d>=10?d:'0'+d} ${h>=10?h:'0'+h}:${mm>=10?mm:'0'+mm}`
    }else{
      str = `${y}/${m>=10?m:'0'+m}/${d>=10?d:'0'+d}`
    }

   return str
}

//转换时间格式
timePlugin.transferYMD = (date,h)=>{
   let nowDate = new Date(date)
   let y = nowDate.getFullYear()
   let m = nowDate.getMonth()+1
   let d = nowDate.getDate()
    let str = ''
    if(!h){
     str = `${y}/${m>=10?m:'0'+m}/${d>=10?d:'0'+d}`
    }else{
     let h = nowDate.getHours()
     let mm = nowDate.getMinutes()
    //  let s = nowDate.getSeconds()
      str = `${y}/${m>=10?m:'0'+m}/${d>=10?d:'0'+d} ${h>=10?h:'0'+h}:${mm>=10?mm:'0'+mm}`
    }
   return str
}

//转换 时
timePlugin.transferHours = (start,end)=>{
  let startDate = new Date(parseInt(start) * 1000)
  let endDate = new Date(parseInt(end) * 1000)
  let startH = startDate.getHours()
  let endH = endDate.getHours()
  let startMinute = startDate.getMinutes()
  let endMinute = endDate.getMinutes()
  let differ = endH - startH
  let topH = 0
  let differH = 0
  let top = startH - 9
  if(startMinute != 30){
    topH = top * 68
  }else{
    topH = (top * 68) + 34
  }

  if(differ == 1){
    differH = differ * 102
    if(startMinute == 30 || endMinute == 30){
      differH += 34
    }
  }
  if(differ == 1 && startMinute == 30 && endMinute == 30){
      differH = differ * 68
  }

  if(differ && differ > 1){
    differH = differ * 68
    if(startMinute == 30 || endMinute == 30){
      differH += 34
    }
    if(startMinute == 0 && endMinute == 30){
      differH += 34
    }
  } differH = (differ * 68) + 34
  if(differ == 0){
    differH = 67
  }
  if(startH == endH && startMinute == endMinute){
    differH = 34
  }
  return {top:topH,height:differH}
}

//转换时间戳为 零点零分
timePlugin.transferStr = (date) => {
  let d = new Date(date)
  d.setHours(0)
  d.setMinutes(0)
  d.setSeconds(0)
  d.setMilliseconds(0)
  let t = parseInt(new Date(d).getTime()/1000)
  return t
}

//转换时间戳为 零点零分
timePlugin.transferHoursStr = (date) => {
  let d = new Date(date)
  let t = parseInt(new Date(d).getTime()/1000)
  return t
}


//转换时间戳为 零点零分
timePlugin.transferStrDate = (date) => {
  let nowDate = date.split('/')
  let y = nowDate[0]
  let m = parseInt(nowDate[1]) - 1
  let d = nowDate[2]
  let day = new Date(y,m,d)
  day.setDate(d)
  day.setHours(0)
  day.setMinutes(0)
  day.setSeconds(0)
  day.setMilliseconds(0)
  let t = parseInt(new Date(day).getTime()/1000)
  return t
}

//场景时间 年月日 + 时分
timePlugin.transferYMAndD = (date,hours)=>{
  let currenDate = new Date(parseInt(date)*1000)
  let h = hours.split(':')
  currenDate.setHours(parseInt(h[0]))
  currenDate.setMinutes(parseInt(h[1]))
  return parseInt(new Date(currenDate).getTime()/1000)
}

//获取前一天
timePlugin.getAfterTime = (date)=>{
  let nowDate = new Date(date)
  let y = nowDate.getFullYear()
  let m = nowDate.getMonth()
  let d = nowDate.getDate() - 1
  return new Date(y,m,d)
}

//获取前一天
timePlugin.getAfterTimeDateYm = (date)=>{
  let nowDate = new Date(date)
  let y = nowDate.getFullYear()
  let m = nowDate.getMonth()
  let d = nowDate.getDate() - 1
  let h = nowDate.getHours()
  let mm = nowDate.getMinutes()
  let str = `${y}/${m>=10?m:'0'+m}/${d>=10?d:'0'+d} ${h>=10?h:'0'+h}:${mm>=10?mm:'0'+mm}`
  return str
}

//获取
timePlugin.getAfterTimeStamp = (date)=>{
  let nowDate = new Date(date)
  let y = nowDate.getFullYear()
  let m = nowDate.getMonth()
  let d = nowDate.getDate()
  let time = new Date(y,m,d)
  return parseInt(new Date(time).getTime()/1000)
}

//日期格式转为 字符串格式 hour是否转换 时分
timePlugin.dateTransferStr = (date,hour)=>{
  let time = new Date(date)
  let y = time.getFullYear()
  let m = time.getMonth() + 1
  let d = time.getDate()
  let str = ''
  if(hour){
    let h = time.getHours()
    let mm = time.getMinutes()
    str = `${y}/${m<10?'0'+m:m}/${d<10?'0'+d:d} ${h<10?'0'+h:h}:${mm<10?'0'+mm:mm} `
  }else{
    str = `${y}/${m<10?'0'+m:m}/${d<10?'0'+d:d}`
  }
  return str
}

//获取前一天 返回时间戳
timePlugin.getTimeStampAfter = (date)=>{
  let nowDate = new Date(parseInt(date)*1000)
  let y = nowDate.getFullYear()
  let m = nowDate.getMonth()
  let d = nowDate.getDate() - 1
  let h = nowDate.getHours()
  let mm = nowDate.getMinutes()
  return new Date(y,m,d,h,mm)
}

//根据时间戳  返回日期格式
timePlugin.getTimeDate = (date)=>{
  let nowDate = new Date(parseInt(date)*1000)
  let y = nowDate.getFullYear()
  let m = nowDate.getMonth()
  let d = nowDate.getDate()
  let h = nowDate.getHours()
  let mm = nowDate.getMinutes()
  return new Date(y,m,d,h,mm)
}
//根据时间戳 返回年月日 时分
timePlugin.getTimeDateHM = (date)=>{
  let nowDate = new Date(parseInt(date)*1000)
  let y = nowDate.getFullYear()
  let m = nowDate.getMonth() + 1
  let d = nowDate.getDate()
  let h = nowDate.getHours()
  // let mm = nowDate.getMinutes()
  let str = `${y}/${m<10?'0'+m:m}/${d<10?'0'+d:d} ${h<10?'0'+h:h}:00 `
  return str
}
//根据时间戳 返回年月日
timePlugin.getDateYMDStr = (date)=>{
  let nowDate = new Date(parseInt(date)*1000)
  let y = nowDate.getFullYear()
  let m = nowDate.getMonth() + 1
  let d = nowDate.getDate()
  // let h = nowDate.getHours()
  // let mm = nowDate.getMinutes()
  let str = `${y}/${m<10?'0'+m:m}/${d<10?'0'+d:d}`
  return str
}
//返回时间戳
timePlugin.getPullStamp = (date)=>{
  let nowDate = new Date(date)
  return parseInt(new Date(nowDate).getTime()/1000)
}

//获取 年月日 时分 返回时间戳
timePlugin.getPullAllHoursStamp = (d)=>{
  let nowDate = new Date(d)
  let h = nowDate.getHours()
  // let m = nowDate.getMinutes()
  nowDate.setHours(h)
  nowDate.setMinutes(0)
  nowDate.setSeconds(0)
  return timePlugin.getPullStamp(nowDate)
}
//获取 年月日 时分 返回时间戳
timePlugin.getPullHoursStamp = (d,hm)=>{
  let nowDate = new Date(d)
  let h = parseInt(hm.split(':')[0])
  let m = parseInt(hm.split(':')[1])
  nowDate.setHours(h)
  nowDate.setMinutes(m)
  nowDate.setSeconds(0)
  return timePlugin.getPullStamp(nowDate)
}

timePlugin.getEndDateByDateStr = function (date) {
  if (date) {
    date = new Date(date);
    date = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59);
    return timePlugin.formatDate(date, 'yyyy-MM-dd hh:mm:ss')
  }else{
    return ''
  }
}
/**
 * 把时间戳类型的转换为Date类型 1524640796
 * @param {*} time
 */
timePlugin.formatTime = function (time) {
  if (!time) return;
  let timeStr = time + '000';
  let timeNew = parseInt(timeStr);
  return new Date(timeNew)
}
/**
 * 格式化时间
 * @param {*} date
 * @param {*} fmt
 */
timePlugin.formatDate = function (date, fmt) {
  if (!date) return;
  if (!fmt) fmt = 'yyyy-MM-dd';
  var o = {
    "M+": date.getMonth() + 1, //月份
    "d+": date.getDate(), //日
    "h+": date.getHours(), //小时
    "m+": date.getMinutes(), //分
    "s+": date.getSeconds(), //秒
    "q+": Math.floor((date.getMonth() + 3) / 3), //季度
    "S": date.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
  return fmt;
}


timePlugin.getDate = function (time, type) {
  let time1 = timePlugin.formatTime(time);
  let date = type ? 'yyyy/MM/dd' : 'yyyy/MM/dd hh:mm'
  return time1?timePlugin.formatDate(time1, date):''
}

timePlugin.getScheduleTime = function (time){
  let nowDate = new Date(parseInt(time)*1000)
  let y = nowDate.getFullYear()
  let m = nowDate.getMonth() + 1
  let d = nowDate.getDate()
  let obj = solarLunar.solar2lunar(y, m, d)
  let mm = obj.lMonth
  let dd = obj.lDay

  let holiday = m + '-' + d
  let ChinaHoliday = mm + '-' + dd
  let holidayValye = ''
  let ChinaHolidayValye = ''
  switch (holiday){
    case '1-1': holidayValye =  '元旦节'; break;
    case '4-5': holidayValye = '清明节'; break;
    case '5-1': holidayValye = '劳动节'; break;
    case '10-1': holidayValye = '国庆节'; break;
  }

  switch (ChinaHoliday){
    case '1-1': ChinaHolidayValye =  '春节'; break;
    case '5-5': ChinaHolidayValye = '端午节'; break;
    case '8-15': ChinaHolidayValye = '中秋节'; break;
  }
  return holidayValye + ChinaHolidayValye
}
export default timePlugin
