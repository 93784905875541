<template>
  <div class="erp-upload">
    <template v-if="type == 'drag'">
      <div class="erp-upload-drag-imgs">
        <el-upload
          id="upload-img-paste"
          class="upload-demo"
          :http-request="uploadOss"
          :before-upload="beforeUpload"
          drag
          :show-file-list="false"
          action="https://jsonplaceholder.typicode.com/posts/"
          :multiple="multiple"
        >
          <svg class="svg-icon_top" aria-hidden="true">
            <use xlink:href="#icon-jia"/>
          </svg>
        </el-upload>
      </div>
    </template>
    <template v-else>
      <el-upload :http-request="uploadOss" :before-upload="beforeUpload" :show-file-list="false" action=""
                 :multiple="multiple">
        <template v-if="styleType==='button'">
          <el-button v-no-more-click :loading="uploadLoading" size="small" :round="round">
            <slot></slot>
          </el-button>
        </template>
        <template v-else>
          <slot></slot>
        </template>
      </el-upload>
    </template>
  </div>
</template>

<script>
import {ossUpload} from "@/API/saas-api";
// import lighthouse from '@/util/lighthouse'
import axios from 'axios'

export default {
  name: "erp-upload",
  props: {
    type: {
      type: String,
      default: ''
    },
    //圆角
    round: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    uploadType: {
      type: Number,
      default: 1
    },
    styleType: {
      type: String,
      default: 'button',
    },
    isPicture: {
      type: Boolean,
      default: false,
    }, // 是否限制为图片格式
    maxSize: [Number, String], // 文件大小限制 ：单位：KB
  },
  data() {
    return {
      uploadLoading: false,

    }
  },
  methods: {
    beforeUpload(file) {
      // 文件类型
      const typeList = new Set(["image/png", "image/jpeg"]);
      if (this.isPicture && !typeList.has(file.type)) {
        this.$message.warning("上传文件格式为jpg/png");
        return false;
      }
      // 文件大小
      const size = file.size / 1000;
      if (this.maxSize && size > this.maxSize) {
        this.$message.warning(`上传文件大小限制为${this.maxSize}KB`);
        return false;
      }
    },
    // 获取OSS TOKEN
    ossTokenAPI() {
      let vm = this;
      return new Promise((resolve) => {
        axios.get('https://lighthouse.inwuoo.com/lighthouse-api/open_oss/get_upload_certificate?systemName=saas-erp').then(data => {
          if (data.data.code == 100000) {
            resolve(data.data.data);
          } else {
            vm.$message.error(data.data.msg);
          }
        })
        // lighthouse.get().then((data) => {
        //
        // });
      });
    },
    getType(file) {
      let filename = file;
      let index1 = filename.lastIndexOf(".");
      let index2 = filename.length;
      let type = filename.substring(index1, index2);
      return type;
    },
    // 上传服务器
    async uploadOss(file) {
      this.uploadLoading = true;
      let tokenInfo = await this.ossTokenAPI();
      let type = this.getType(file.file.name);
      let formData = new FormData();
      let fileUrl =
        tokenInfo.key +
        new Date().getTime() +
        Math.floor(Math.random() * 150) +
        type;
      // 设置阿里云密钥
      formData.append("key", fileUrl);
      formData.append("policy", tokenInfo.policy);
      formData.append("OSSAccessKeyId", tokenInfo.accessid);
      formData.append("success_action_status", 200);
      formData.append("signature", tokenInfo.signature);
      formData.append("file", file.file, file.file.name);
      // 上传到阿里云
      ossUpload(tokenInfo.host, formData)
        .then(() => {
          this.$emit("getFile", `${tokenInfo.host}/${fileUrl}`, file.file.name);
          this.uploadLoading = false;
        })
        .catch((err) => {
          console.log(err)
          this.$message.error("上传错误", err);
          ``
          this.uploadLoading = false;
        });
    },
  }
}
</script>

<style scoped lang="less">
.svg-icon_top {
  width: 50px;
  height: 50px;

}

.erp-upload-drag-imgs {
  width: 90px;
  height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px dashed #cccccc;
  border-radius: 10px;
  &:hover {
    border-color: #409eff;
  }

  .upload-demo {
    height: 50px;
    .el-upload {
      height: 100% !important;
      width: 100% !important;
    }

  }
}
</style>
<style lang="less">
.erp-upload-drag-imgs {
  .el-upload-dragger {
    border: 0;
    padding: 0 !important;
  }
}

</style>
