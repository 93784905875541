<template>
  <div class="erp-time-picker">
    <el-date-picker
      size="small"
      v-model="timeValue"
      :type="type"
      range-separator="-"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      @change="timeChange"
      :clearable="clearable"
      style="width: 230px"
    >
    </el-date-picker>
  </div>
<!--  :shortcuts="optionStatus ? pickerOptions.shortcuts : {}"-->

</template>

<script>
import TimeUtil from "@/util/time-util";
export default {
  name: "erp-time-picker",
  props: {
    type: String,
    dataTime: {
      type: Array,
      default: () => {
        return [];
      },
    },
    optionStatus: {
      type: Boolean,
      default: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    dataTime: {
      //深度监听，可监听到对象、数组的变化
      handler(newV, oldV) {
        if (this.clearable && newV[0] === 0 && newV[1] === 0) {
					this.timeValue = ["", ""];
        } else if (newV.length && newV != oldV) {
          this.timeValue = newV;
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      timeValue: [],
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            value:() =>{
              const now = new Date();
              const year = now.getFullYear();
              const month =
                now.getMonth() + 1 > 10
                  ? now.getMonth() + 1
                  : "0" + (now.getMonth() + 1);
              const day =
                now.getDate() > 10 ? now.getDate() : "0" + now.getDate();
              const end = `${year}/${month}/${day} 23:59:59`;
              const start =
                day - 7 > 0
                  ? `${year}/${month}/${day - 7} 00:00:00`
                  : new Date(end).getTime() - 1000 * 3600 * 24 * 7 + 1000;
              return [start, end]
            },
          },
          {
            text: "最近一个月",
            value:() =>{
              const now = new Date();
              const year = now.getFullYear();
              const month =
                now.getMonth() + 1 > 10
                  ? now.getMonth() + 1
                  : "0" + (now.getMonth() + 1);
              const day =
                now.getDate() > 10 ? now.getDate() : "0" + now.getDate();
              const start =
                month - 1 > 0
                  ? `${year}/${month - 1}/${day} 00:00:00`
                  : `${year - 1}/${12}/${day} 00:00:00`;
              const end = `${year}/${month}/${day} 23:59:59`;
              return [start, end]
            },
          },
          {
            text: "最近三个月",
            value:() =>{
              const now = new Date();
              const year = now.getFullYear();
              const month =
                now.getMonth() + 1 > 10
                  ? now.getMonth() + 1
                  : "0" + (now.getMonth() + 1);
              const day =
                now.getDate() > 10 ? now.getDate() : "0" + now.getDate();
              const start =
                month - 3 > 0
                  ? `${year}/${month - 3}/${day} 00:00:00`
                  : month - 3 == 0
                  ? `${year - 1}/12/${day} 00:00:00`
                  : month - 3 == -1
                  ? `${year - 1}/11/${day} 00:00:00`
                  : `${year - 1}/10/${day} 00:00:00`;
              const end = `${year}/${month}/${day} 23:59:59`;
              return [start, end]
              // picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  methods: {
    timeChange(data) {
      console.log(data)
      if (data) {
        let endTime = TimeUtil.formatDate(new Date(data[1]), "yyyy/MM/dd");
        endTime = TimeUtil.getTimeStamp(endTime + " 23:59:59");
        this.$emit("change", {
          startTime: Math.floor(new Date(data[0]).getTime() / 1000),
          endTime: endTime,
        });
      } else {
        this.$emit("change", {
          startTime: "",
          endTime: "",
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
.erp-time-picker {
  margin-left: 10px;
}
</style>
