<template>
  <div class="history-tag">
    <div class="border">
      <el-tabs v-model="activeName" type="card" closable @tab-click="handleClick" @tab-remove="close">
        <el-tab-pane
            v-for="(item, index) in tagsList"
            :key="index"
            :label="item.name"
            :name="index +''"
        >
        </el-tab-pane>
      </el-tabs>

    </div>
    <div
        class="border_1"
        style="
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
      "
    >
      <el-button
          size="small"
          round
          style="margin-right: 20px;font-size: 12px"
          @click="clear"
      >清除
      </el-button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "history-tag",
  data() {
    return {
      width: 0,
      activeName: '',
      tags: [],
    };
  },
  computed: {
    ...mapGetters(["getcontrolModuleSet"]),
    tagsList() {
      console.log(this.tags)
      return this.tags
    },
  },
  created() {
  },
  mounted() {
    this.width = document.body.clientWidth - 240;
  },
  methods: {
    clear() {
      this.tags = []
    },
    close(index) {
      this.tags.splice(index, 1);
      let item = this.tags[this.tags.length - 1]
      if(item){
        this.$router.push(item.url);
      }
    },
    handleClick() {
      this.$nextTick(()=>{
        let url = this.tagsList[parseInt(this.activeName)].url
        this.$router.push(url);
      })
    },
    routerMate(to) {
      let start = this.tags.some(({url}) => url == to.fullPath);
      if (!start) {
        if (this.tags.length >= 12) {
          this.tags.splice(0, 1);
        }
        this.tags.push({
          name: to.path == "/module/order-detail"
            ? to.query.orderNum
            : to.meta.title,
          url: to.fullPath,
          type: "",
        });
      }
      this.tags.map((item, index) => {
        if (to.query.orderNum) {
          if (item.name == to.query.orderNum) {
            this.activeName = index + ''
          }
        } else if (item.name == to.meta.title) {
          this.activeName = index + ''
        }
        return item;
      });
    }
  },
  watch: {
    // 监听,当路由发生变化的时候执行
    $route(to) {
      this.routerMate(to);
    },
  },
};
</script>

<style scoped lang="less">
.history-tag {
  display: flex;
  background: #fff;
  border-bottom: 1px solid #d9d9d9;
  .border {
    background: #fff;
    display: flex;
    align-items: center;
    overflow-x: auto;
    padding: 5px 5px 0px 5px;
    width: 100%;
    overflow: hidden;
    //margin-top: 10px;
  }
}
</style>


<style lang="less">
.history-tag {
  .el-tabs {
    .el-tabs__header {
      margin: 0 !important;
      border-bottom: none;
      .el-tabs__nav-scroll{
        .el-tabs__nav{
          .el-tabs__item{
            font-size: 12px;
          }
        }
      }
    }
  }
}

</style>
