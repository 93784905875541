<template>
	<div class="erp-search-button">
		<el-dropdown @command="changeTime">
			<el-button @click="searchTable" type="primary" size="small" :round="roundFlag" :loading="saveLoading">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-sousuo"></use>
        </svg>
        搜 索
      </el-button>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item command="today">今天</el-dropdown-item>
          <el-dropdown-item command="yesterday">昨日</el-dropdown-item>
          <el-dropdown-item command="tomorrow">明天</el-dropdown-item>
          <el-dropdown-item command="thisWeek">本周</el-dropdown-item>
          <el-dropdown-item command="lastWeek">上周</el-dropdown-item>
          <el-dropdown-item command="thisMonth">本月</el-dropdown-item>
          <el-dropdown-item command="lastMonth">上月</el-dropdown-item>
          <el-dropdown-item command="thisQuarter">本季度</el-dropdown-item>
          <el-dropdown-item command="lastQuarter">上季度</el-dropdown-item>
        </el-dropdown-menu>
      </template>
		</el-dropdown>
	</div>
</template>

<script>
import TimeUtil from "@/util/time-util";
import TimeSearch from "@/util/time-search";
export default {
	name: "erp-search-button",
	data() {
		return {
			saveLoading: false,
		};
	},
	props:{
		roundFlag:{
			type:Boolean,
			default:true
		}
	},
	methods: {
		changeTime(data) {
			let start = "";
			let end = "";
			switch (data) {
				case "today":
					start = TimeSearch.getYmdToday();
					end = TimeSearch.getYmdToday();
					break;
				case "yesterday":
					start = TimeSearch.getYesterDay();
					end = TimeSearch.getYesterDay();
					break;
				case "tomorrow":
					start = TimeSearch.getTomorrow();
					end = TimeSearch.getTomorrow();
					break;
				case "thisWeek":
					start = TimeSearch.getFirstYmdThisWeek();
					end = TimeSearch.getLastYmdThisWeek();
					break;
				case "lastWeek":
					start = TimeSearch.getLastWeekFistDay();
					end = TimeSearch.getLastWeekLastDay();
					break;
				case "thisMonth":
					start = TimeSearch.getYmdMonthFirst();
					end = TimeSearch.getYmdMonthLast();
					break;
				case "lastMonth":
					start = TimeSearch.getLastYmdMonthFirst();
					end = TimeSearch.getLastYmdMonthEnd();
					break;
				case "thisQuarter":
					start = TimeSearch.getFirstDayOfThisQuarter();
					end = TimeSearch.getEndDayOfThisQuarter();
					break;
				case "lastQuarter":
					start = TimeSearch.getFirstDayOfLastQuarter();
					end = TimeSearch.getEndDayOfLastQuarter();
					break;
			}
			start = TimeUtil.getStartDateByDateStr(start);
			end = TimeUtil.getEndDateByDateStr(end);
			this.$emit("searchTable", [start, end]);
		},
		searchTable() {
			this.$emit("searchTable");
		},
	},
};
</script>

<style scoped lang="less">
.erp-search-button {
	margin-left: 10px;
}
</style>
