<template>
  <div class="erp-menu">
    <div class="mennu-top" :class="!isCollapse ? 'mennu-top-header-mini':'mennu-top-header'">
<!--      <div class="mennu-top-nav">-->
<!--                <span @click="isShow">-->
<!--                  <div class="input-ico" :class="!isCollapse ? 'left':''">-->
<!--                      <svg class="icon" aria-hidden="true"><use xlink:href="#icon-xiangyougengduo"></use></svg>-->
<!--                  </div>-->
<!--                </span>-->
<!--      </div>-->
      <div class="mennu-top-nav">
        <el-button @click="isShow" circle>
          <el-icon v-if="!isCollapse">
            <svg style="width: 0.8em; height: 0.8em" viewBox="0 0 1024 1024" data-v-029747aa=""><path fill="currentColor" d="M685.248 104.704a64 64 0 0 1 0 90.496L368.448 512l316.8 316.8a64 64 0 0 1-90.496 90.496L232.704 557.248a64 64 0 0 1 0-90.496l362.048-362.048a64 64 0 0 1 90.496 0z"></path></svg>
          </el-icon>
          <el-icon v-else>
            <svg style="width: 0.8em; height: 0.8em" viewBox="0 0 1024 1024" data-v-029747aa=""><path fill="currentColor" d="M338.752 104.704a64 64 0 0 0 0 90.496l316.8 316.8-316.8 316.8a64 64 0 0 0 90.496 90.496l362.048-362.048a64 64 0 0 0 0-90.496L429.248 104.704a64 64 0 0 0-90.496 0z"></path></svg>
          </el-icon>
        </el-button>
      </div>
      <div class="mennu-top-header-container">
        <div :class="!isCollapse?'mennu-top-header-left':'mennu-top-header-left-mini'">
          <img v-if="getLoginCompanyInfo.logo" :src="getLoginCompanyInfo.logo" alt="">
          <img v-else src="https://weddingbo-1256393015.file.myqcloud.com/DefaultImg/head5.png" alt="">
        </div>

        <div class="mennu-top-header-right" v-show="!isCollapse">
          <div class="mennu-top-header-right-name">{{ getLoginCompanyInfo.companyName }}</div>
          <div class="mennu-top-header-right-company">{{ getUserInfo.nickName }}</div>
        </div>
      </div>
    </div>
    <el-menu
      class="el-menu-vertical-demo"
      :collapse="isCollapse"
      :default-active="$route.path"
      :default-openeds="defaultOpeneds"
      @open="handleOpen"
      @close="handleClose"
    >
      <el-menu-item :index="item.link" v-for="(item,index) in menuTitle" :key="index" @click="open(item)">
        <el-icon>
          <svg class="icon" aria-hidden="true">
            <use :xlink:href="item.ico"></use>
          </svg>
        </el-icon>
        <template #title> {{ item.name }}</template>
      </el-menu-item>
      <div class="el-menu-footer">
        <el-menu-item :index="item.link" v-for="(item,index) in footerList" :key="index" @click="open(item)">
          <el-icon>
            <svg style="width: 0.8em; height: 0.8em" class="icon" aria-hidden="true">
              <use :xlink:href="item.ico"></use>
            </svg>
          </el-icon>
          <template #title> {{ item.name }}</template>
        </el-menu-item>
        <el-menu-item index="footer" @click="exit">
          <el-icon>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-guanji"></use>
            </svg>
          </el-icon>
          <template #title> 退出登陆</template>
        </el-menu-item>
      </div>
    </el-menu>
  </div>

</template>
<script>
/* eslint-disable  */
import {getPermission} from '@/API/saas-api'
import Cookie from "js-cookie";
import {mapGetters} from "vuex";

export default {
  name: "erp-menu",
  props: {
    menuTitle: Array,
    defaultOpeneds: Array
  },
  computed: {
    ...mapGetters(['getLoginCompanyInfo', 'getUserInfo']),
  },
  data() {
    return {
      isCollapse: false,
      active: '0,0',
      titleBg: null,
      menuShow: true,
      title: [],
      footerList:[ // 底部列表
        {
          name: "设置",
          link: "/module/company-set",
          ico:'#icon-xitong',
          permissions: 0
        }
      ]
    };
  },
  created() {
    this.open(this.menuTitle[0])
    this.getBaseInfo()
  },
  mounted() {

  },
  watch: {
    isCollapse: function (newValue) {
      this.$emit('widthActive', newValue)
    },
  },
  methods: {
    exit() {
      Cookie.remove("token");
      this.$router.push({
        name: "login",
      });
    },
    getBaseInfo() {
      getPermission({
        token: Cookie.get("token"),
      }).then(res => {
        if (res.code === 100000) {
          this.$store.commit('setLoginCompanyInfo', res.data.companyInfo)
          this.$store.commit('setUserInfo', res.data.staffInfo)
        }
      })
    },
    isShow() {
      this.isCollapse = !this.isCollapse
    },
    handleOpen() {

    },
    handleClose() {

    },
    open(item) {
      if (item.link) {
        let url = item.id ? item.link + '?listId=' + item.id : item.link
        this.$router.push({
          path: url
        })
      }
    },
  },
};
</script>
<style scoped lang="less">
.erp-menu {
  background: #F1F1F1;
  .el-menu {
    border-right: 0;
    .el-menu-item {
      font-size: 12px;
    }
  }

  .el-menu-vertical-demo {
    width: 70px;
    background: #F1F1F1;
    position: relative;
    height: calc(100vh - 60px);
    padding-top: 16px;

    .el-menu-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0 10px;
      .nav {
        height: 46px;
        line-height: 46px;
        border-radius: 8px;
        text-align: center;

        &:hover {
          background: #ffffff;
        }
      }

    }
  }

  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 180px;
    border-top: 0;
    height: calc(100vh - 110px);

  }

  .mennu-top {
    //border-right: 1px solid #e1e3e6;
  }

  .mennu-top-header {
    position: relative;
    height: 55px;
    width: 70px;
    transition-property: width, height;
    transition-duration: 0.3s;

    .mennu-top-nav {
      position: absolute;
      right: -15px;
      top: 12px;
      .el-button {
        padding: 7px;
      }

      .input-ico {
        font-size: 20px;
        color: #000000
      }

      .left {
        transform: rotate(180deg);
      }
    }

    .mennu-top-header-container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #e1e3e6;

      .left-img {

      }
    }

    .mennu-top-header-left-mini {
      width: 40px;
      height: 40px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
      }
    }
  }

  .mennu-top-header-mini {
    transition-property: width, height;
    transition-duration: 0.3s;
    width: 180px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-bottom: 1px solid #e1e3e6;
    position: relative;

    .mennu-top-nav {
      position: absolute;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
      .el-button {
        padding: 7px;
        height: unset;
      }

      .input-ico {
        font-size: 20px;
        color: #000000
      }

      .left {
        transform: rotate(180deg);
      }
    }

    .mennu-top-header-container {
      display: flex;
      align-items: center;
      width: 200px;
      overflow: hidden;
    }

    .mennu-top-header-left {
      width: 60px;
      height: 60px;
      border-radius: 9999px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 20px;

      img {
        width: 100%;
      }
    }

    .mennu-top-header-right {
      text-align: center;
      padding: 0 10px;

      .mennu-top-header-right-name {
        font-size: 14px;
        font-weight: 600;
      }

      .mennu-top-header-right-company {
        font-size: 12px;
        margin-top: 5px;
      }
    }
  }
}
</style>
<style lang="less">
.erp-menu {
  .el-menu {
    background: #f0f2f5;
    box-sizing: border-box;
    padding: 0 10px;

    .el-menu-item {
      height: 46px;
      line-height: 46px;
      .el-menu-tooltip__trigger {
        padding: 0 !important;
        left: 25%;
      }
      &:hover{
        background: #fff;
        color: #0093ff !important;
        border-radius: 8px;
      }
    }

    .is-active {
      background: #fff;
      color: #0093ff !important;
      border-radius: 8px;
    }
  }
}
</style>
