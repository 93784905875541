<template>
	<div class="erp-tabs">
		<div class="erp-tabs-default" v-if="type == 'default'">
			<div
				class="el-tabs__active-bar"
				style="background: #2F88FF"
				:style="{ width: tabWidth + 'px', transform: 'translateX(' + translateX + 'px)', zIndex: 0 }"
			></div>
			<div
				:key="index"
				ref="tabs"
				@click="changeTabs(index, item)"
				:class="{ active: item.index == selectTabs }"
				v-for="(item, index) in tabsDataValue"
				class="list cursor"
				:style="{ fontSize: size == 'small' ? '14px' : '12px' }"
			>
				{{ item.name }}
				<span v-if="item.num || item.num == 0">({{ item.num }})</span>
			</div>
		</div>
		<div class="erp-tabs-border" :style="erpTabsBorderStyle" v-if="type == 'border'">
			<div class="head-list" :style="headListStyle">
				<div
					v-for="(item, index) in tabsDataValue"
					:class="{ 'head-box': true, active: item.index == selectTabs }"
					:key="index"
					ref="borderTabs"
					@click="changeCurrentTab(index, item)"
				>
					{{ item.name }}
					<span v-if="item.num || item.num == 0">({{ item.num }})</span>
				</div>
			</div>
		</div>
    <div class="-button--">
      <slot name="set-button"></slot>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
	name: "erp-tabs",
	props: {
		tabsData: {
			type: Array,
		},
		defaultIndex: {
			type: Number,
			default: 1,
		},
		type: {
			default: "default",
			type: String,
		},
		size: {
			default: "mini",
			type: String,
		},
		headListStyle: {
			default: "",
			type: String,
		},
		erpTabsBorderStyle:{
			default: "",
			type: String,
		}
	},
	computed: {
		...mapGetters(["getcontrolModuleSet"]),
	},
	watch: {
		tabsData(newValue) {
			if (newValue) {
				this.tabsDataValue = [];
				newValue.forEach((res) => {
					if (res.position) {
						if (this.getcontrolModuleSet.has(res.position)) {
							this.tabsDataValue.push(res);
						}
					} else {
						this.tabsDataValue.push(res);
					}
				});
			}
		},
	},
	data() {
		return {
			selectTabs: 1,
			tabWidth: 80,
			translateX: 30,
			tabsDataValue: [],
		};
	},
	created() {
		this.tabsData.forEach((res) => {
			if (res.permission) {
				if (this.getcontrolModuleSet.has(res.permission)) {
					this.tabsDataValue.push(res);
				}
			} else {
				this.tabsDataValue.push(res);
			}
		});
	},
	mounted() {
		if (this.type == "default") {
			this.tabWidth = this.$refs.tabs[0].offsetWidth;
		} else {
			this.tabWidth = this.$refs.borderTabs[0].offsetWidth;
		}
		if (this.defaultIndex !== 1) {
			this.changeTabs(this.defaultIndex - 1, { index: this.defaultIndex });
		}
	},
	methods: {
		changeTabs(index, item) {
			const tabs = this.$refs.tabs[index] || this.$refs.tabs[0]
			this.tabWidth = tabs.offsetWidth;
			this.translateX = tabs.offsetLeft;
			this.selectTabs = item.index;
			this.$emit("change", item.index);
		},
		changeCurrentTab(index, item) {
			this.selectTabs = item.index;
			this.$emit("change", item.index);
		},
		changeInit(id) {
			this.selectTabs = id;
			this.$emit("change", id);
		},
	},
};
</script>

<style scoped lang="less">
.erp-tabs {
  position: relative;
  .-button--{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;
  }
	.erp-tabs-default {
		height: 55px;
		line-height: 41px;
		padding-top: 10px;
		border-bottom: 1px solid #d9d9d9;
		padding-left: 30px;
		position: relative;
		.el-tabs__active-bar {
			bottom: -1px;
		}
		.list {
			display: inline-block;
			min-width: 60px;
			line-height: 34px;
			height: 100%;
			text-align: center;
			margin-right: 50px;
			padding-left: 6px;
			padding-right: 6px;
			cursor: pointer;
			transition: all 0.3s;
			&.active {
				color: #2f88ff;
			}

			&:hover {
				color: #2f88ff;
			}
		}
	}
	.erp-tabs-border {
		.head-list {
			display: flex;
			align-content: center;
			padding-top: 20px;
			color: #222;

			.head-box {
				box-sizing: border-box;
				border-left: 1px solid #d9d9d9;
				border-top: 1px solid #d9d9d9;
				padding: 0 20px;
				cursor: pointer;
				font-size: 12px;
				height: 30px;
				line-height: 30px;
				transition: all 0.3s;
				&:hover {
					color: #2f88ff;
				}
				&:first-child {
					border-top-left-radius: 4px;
				}

				&:last-child {
					border-top-right-radius: 4px;
				}
			}

			.head-box:last-child {
				border-right: 1px solid #d9d9d9;
			}

			.head-box.active {
				color: #fff;
				background-color: #2f88ff;
			}
		}
	}
}
</style>
