import axios from 'axios'
const config = require('./config')
import {ElMessage} from 'element-plus'  // 引入element-plus
import router from '../router'  // 由于有些token认证失效等需要用到，所以引入router
import Cookie from "js-cookie";

// 定义初始化状态码
const TOKEN_INVALID = 'Token认证失败, 请重新登录'
const NETWORK_ERROR = '网络请求异常, 请稍后重试'
axios.defaults.timeout = 10000
const service = axios.create({
  baseURL: config.PERSONNEL,
  // 接口持续时间为8秒，否则超时
  timeout: 8000
})
// 请求拦截
service.interceptors.request.use((req) => {
  // 获取请求头
  req.headers['token'] = Cookie.get('token')
  return req
})
// 响应拦截
service.interceptors.response.use((res) => {
  // 获取后端返回的code，data和提示语
  const {code, msg} = res.data
  if (code == 100000) return res.data
  else if (code === 50001) {   // token认证失败
    ElMessage.error(TOKEN_INVALID)   // 给予5001的状态码
    // 并且给予用户 一定的反应时间后，跳转登录页
    setTimeout(() => {
      router.push('/')
    }, 15000)
    return Promise.reject(TOKEN_INVALID)  // 抛出异常
  } else {
    // 丢出服务器异常
    ElMessage.error(msg + ' code:' +code || NETWORK_ERROR)
    return Promise.reject(msg || NETWORK_ERROR)
  }
})



const http = {
  get(url, params) {
    return new Promise((resolve, reject) => {
      service
        .get(url, params)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  post(url, params) {
    return new Promise((resolve, reject) => {
      service
        .post(url, params)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err.data)
        })
    })
  }
}
export default http
